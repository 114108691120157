import $ from 'jquery';
import '/node_modules/bxslider/dist/jquery.bxslider.min.js';

export default function carousel() {
    $('.displayPropertyBanner').bxSlider({
        minSlides: 1,
        maxSlides: 1,
        moveSlides: 1,
        slideWidth: 700,
        pager: true,
        auto: true,
        controls: true,
        autoHover: true,
        prevText: "<i class='material-icons icon-chevron-left'></i>",
        nextText: "<i class='material-icons icon-chevron-right'></i>"
    });
}
