/* eslint-disable */
$(function () {
	if($('#eventsCarousel').length){
		$('#eventsCarousel').slick({
			infinite: true,
			speed: 300,
			slidesToShow: 3,
			slidesToScroll: 3,
			arrows: false,
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 720,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 540,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});

	}

	if($('#newsCarousel').length){
		$('#newsCarousel').slick({
			infinite: true,
			speed: 300,
			slidesToShow: 3,
			slidesToScroll: 3,
			arrows: false,
			adaptiveHeight: true,
			responsive: [
				{
					breakpoint: 720,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 540,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	}

	if($('.js-slider-team-carousel').length) {
		$('.js-slider-team-carousel').slick({
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: true,
			adaptiveHeight: false,
			prevArrow: "<i class='carousel-prev material-icons slick-arrow icon-chevron-left'></i>",
			nextArrow: "<i class='carousel-next material-icons slick-arrow icon-chevron-right'></i>"
		});


	}


	var cityAdvertsSlider = $('#rotating-item-wrapper').slick({
		autoplay: true,
		autoplaySpeed: 2500,
		infinite: true,
		speed: 1500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: false,
		fade: true,
		cssEase: 'linear'
	});

	// Here is most important part of the code which actually stops the slider
	cityAdvertsSlider.on('hover', function (event, slick, currentSlide) {
		// slick - is a slider object with a lot of useful info
		// currentSlide - is a current slide index (starts from 0)
		if (slick.slideCount === currentSlide + 1) {
			slick.paused = true;
		}
	});

	$('.slide-next').click(function (e) {
		e.preventDefault();
		var carouselToControl = $(this).data('parent-carousel');

		$(carouselToControl).slick('slickNext');
	});

	$('.slide-prev').click(function (e) {
		e.preventDefault();
		var carouselToControl = $(this).data('parent-carousel');

		$(carouselToControl).slick('slickPrev');
	});
});
